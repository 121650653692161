import { Heading, HeadingProps } from "@chakra-ui/react"
import * as React from "react"

export type BlockHeaderProps = HeadingProps & {
  children: React.ReactChild
  size?: Pick<HeadingProps, `fontSize`>
  theme?: `normal` | `dark` | `light`
}

function BlockHeader({
  children,
  size = `sm`,
  theme = `normal`,
  ...rest
}: BlockHeaderProps): JSX.Element {
  const Themes = {
    normal: { bg: `red.500`, color: `white` },
    dark: { bg: `black`, color: `white` },
    light: { bg: `white`, color: `red.500` },
  }
  return (
    <Heading
      as="h1"
      bg={Themes[theme].bg}
      color={Themes[theme].color}
      fontFamily="body"
      fontSize={size}
      fontWeight="black"
      letterSpacing="widest"
      px={4}
      py={1}
      rounded="md"
      textTransform="uppercase"
      {...rest}
    >
      {children}
    </Heading>
  )
}

export default BlockHeader
