import * as React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"

export interface HeroProps {
  className?: string
  content?: React.ReactNode
  image: React.ReactNode
}

const Hero: React.FC<HeroProps> = ({
  className,
  content,
  image,
}): JSX.Element => {
  return (
    <Box
      className={className ?? null}
      h={{ base: `calc(100vh - 4.5rem)`, md: `80vh` }}
      left="0px"
      overflow="hidden"
      pos="relative"
      top="0px"
      w="100vw"
    >
      <Box h="100%" left="0px" pos="absolute" top="0px" w="100%" zIndex={1}>
        {image}
      </Box>
      <Box
        bg="blackAlpha.500"
        h="100%"
        left="0px"
        pos="absolute"
        top="0px"
        w="100%"
        zIndex={1}
      />

      {content && (
        <Flex
          align="center"
          flexDir="column"
          h="100%"
          justify="center"
          left="0"
          pos="absolute"
          top="0"
          w="100%"
          zIndex={1}
        >
          {content}
        </Flex>
      )}
    </Box>
  )
}

export default Hero
